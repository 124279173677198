<template>
  <v-container fluid class="pb-8">
    <v-row>
      <v-col>
        <h3 class="mb-2">Negative bookings by region</h3>

        <v-simple-table>
          <thead>
            <tr class="pb-4">
              <th>Vertical</th>
              <th>Provider</th>
              <th>Region</th>
              <th>Approved?</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in items" :key="i.id" :class="nkw_getApprovalStatusClass(i.approval_status)">
              <td>{{i.vertical_short_name}}</td>
              <td>{{i.provider}}</td>
              <td>{{i.region}}</td>
              <td><v-icon>{{ nkw_getApprovalStatusIcon(i.approval_status) }}</v-icon></td>
              <td>
                <v-btn v-if="hasSEAPermissions" v-show="i.approval_status !== 'Approved'" icon @click="changeApprovalStatus(i.id, 'Approved')">
                  <v-icon small>done</v-icon>
                </v-btn>
                <v-btn v-if="hasSEAPermissions" v-show="i.approval_status !== 'Declined'" icon @click="changeApprovalStatus(i.id, 'Declined')">
                  <v-icon small>close</v-icon>
                </v-btn>
                <v-btn icon :disabled="!showDelete(i.provider_is_protected)" @click="deleteItem(i.id)">
                  <v-icon small>delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn class="mt-3" color="primary" @click="showCreateItem()">Add regions</v-btn>

        <v-dialog v-model="showModal" persistent max-width="600px">
          <v-card>

            <v-card-title>
              <span class="headline">Create region booking</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form ref="form">
                      <v-autocomplete v-model="selectedVertical" :items="activeVerticals" item-value="id" item-text="name" label="Vertical"
                       required :rules="[v => !!v || 'Vertical is required']"></v-autocomplete>
                      <v-autocomplete v-model="selectedProvider" :items="activeProviders" item-value="id" item-text="name" label="Provider"
                       required :rules="[v => v >= 0 || 'Provider is required']"></v-autocomplete>
                      <v-autocomplete v-model="selectedRegion" :items="regions" item-value="id" item-text="name" label="Region"
                       required :rules="[v => !!v || 'Region is required']"></v-autocomplete>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
              <v-btn color="blue darken-1" text @click="createItem()">Save</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '@/auth'
import dataTablesMixin from '@/mixins/datatables'
import formsMixin from '@/mixins/forms'
import nkwMixin from '@/mixins/negativeKeywords'

export default {
  name: 'NKWRegions',

  props: {
    nkwID: { type: Number, required: true },
    nkwType: { type: String, required: true },
  },

  data () {
    return {
      items: [],
      item: {
        vertical_short_name: null,
        provider: null,
        provider_is_protected: null,
        region: null,
        approval_status: null,
      },
      valid: false,
      showModal: false,
      loading: false,
      selectedID: null,
      selectedVertical: null,
      selectedProvider: null,
      selectedRegion: null,
      actionEndpoint: '/a/nkw/bookings',
      dataEndpoint: '/a/nkw/bookings?regions_fk=-0',
      changeApprovalStatusEndpoint: 'a/nkw/change-approval-status'
    }
  },

  mixins: [dataTablesMixin, formsMixin, nkwMixin],

  computed: {
    activeProviders () {
      if (this.nkwType === 'Brand') {
        return this.$store.state.core.activeProviders.filter(val => val.id !== 0)
      }
      return this.$store.state.core.activeProviders.filter(val => val.id == 0)
    },
    activeVerticals () {
      return this.$store.state.core.activeVerticals.filter(val => val.id !== 0)
    },
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
    regions () {
      return this.$store.state.common.regions.filter(val => val.id !== 0)
    },
  },

  methods: {
    closeModal: function () {
      this.selectedID = null
      this.selectedVertical = null
      this.selectedProvider = null
      this.selectedRegion = null
      this.showModal = false
    },

    changeApprovalStatus: function (bookingID = 0, newStatus = '') {
      var changeItem = new Object
      changeItem['newApprovalStatus'] = newStatus
      changeItem['bookingIDs'] = [bookingID]
      var body = JSON.stringify(changeItem)

      this.$http.put(this.changeApprovalStatusEndpoint, body).then(resp => {
        this.$emit('changed')
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.changeApprovalStatusEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    createItem: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      var newItem = this.getItem()
      var body = JSON.stringify(newItem)
      this.$http.post(this.actionEndpoint, body).then(resp => {
        this.$emit('changed')
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.actionEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.actionEndpoint + '/' + id + '/soft'

      this.$http.delete(myURL).then(resp => {
        this.$emit('changed')
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    fetchData: function () {
      this.loading = true

      var myURL = this.dataEndpoint + '&xsort=vertical,provider,region&xfields='
      // only get the fields needed to fill the item object
      myURL = this.forms_addItemFieldsToURI(myURL, this.item, ['id'])

      // filter for this NKW only
      myURL += '&keywords_fk=' + this.nkwID

      this.$http.get(myURL).then(resp => {
        this.items = resp.data.data
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    getItem: function () {
      var newItem = new Object

      newItem['keywordID'] = this.nkwID
      newItem['providerID'] = this.selectedProvider
      newItem['verticalID'] = this.selectedVertical
      newItem['regionID'] = this.selectedRegion
      newItem['countryID'] = 0

      return newItem
    },

    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      
      this.showModal = true
    },

    showDelete: function (providerIsProtected) {
      if (providerIsProtected) {
        if (auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      } else {
        if (auth.hasPermission('SEA Basic') || auth.hasPermission('SEA Senior') ||  auth.hasPermission('Tech')) {
          return true
        }
      }
      return false
    }
  },

  created: function () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}

</script>

<style scoped>
</style>